export const HasLicenceOptions = Object.freeze({
  notAnswered: '',
  yes: 'yes',
  no: 'no'
});
export const hasDriversLicenceOptions = [
  {
    label: 'No',
    value: HasLicenceOptions.no,
  },
  {
    label: 'Yes',
    value: HasLicenceOptions.yes,
  }
]
