
/* QuoteDeclinedSection
 *
 */
import _ from 'lodash';
import {Link, Text} from 'basis';
import React from 'react';
import {getSalesforceBrokerUrl} from '@/utils/getUrlFromEnvUtil';
import {Title} from '@/components/PersonalLoanSoftQuote/retrieve/RetrieveQuoteSection';

export const QuoteDeclinedSection = props => {
  const broker = props.broker;

  return (
    <>
      <Title>
        Sorry
        {_.capitalize(props.userData.firstName) && <>, {_.capitalize(props.userData.firstName)}</>}
      </Title>

      <Text align="center" margin="0 4">
        Unfortunately the details you&apos;ve provided do not meet our lending
        criteria, so we cannot offer {broker ? 'your client' : 'you'} a Personal
        Loan quote at this stage. <br />
        <br />
        Even though we are unable to assist{' '}
        {broker
          ? "your client this time, we'll send them an email in 48 " +
          'hours time providing more information on this decision.'
          : "you this time, we've sent you  an email providing " +
          'more information on this decision.'}{' '}
        {broker && (
          <>
            <br />
            <br />
            You can log into the{' '}
          </>
        )}
        {broker && (
          <>
            <Link href={getSalesforceBrokerUrl()}>Broker Dashboard</Link>
            <>
              {' '}
              to review the reasons for being declined and the quote details.
            </>
          </>
        )}
      </Text>

      {/* TODO - replace placeholder with correct svg icon */}
      <img
        src={require('@/images/placeholder-email icon.png')}
        alt="email"
        css="max-width:90px; margin: 0 auto;"
      />
    </>
  );
};
