import React from 'react';
import AppendHead from 'react-append-head';

function DatadogAppender() {
  return (
      <AppendHead>
        <script
          name="datadog-main"
          order="0"
          src="https://www.datadoghq-browser-agent.com/datadog-rum-us.js"
          type="text/javascript"
        />
        <script name="datadog-client" order="1">
          {`
                  window.DD_RUM.init({
                     applicationId: 'd2b21a10-18b7-48b9-ac22-eb01aa500a22',
                     clientToken: 'pub042427d9dadfb2b32271180aead03f6b'
                  });
                  `}
        </script>
      </AppendHead>

  )
}

export { DatadogAppender };
