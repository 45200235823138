import { HasLicenceOptions } from '../constants/driversLicence';

const PAGES = {
  ABOUT_YOU: 'ABOUT_YOU',
  EMPLOYMENT: 'EMPLOYMENT',
  YOUR_QUOTE: 'YOUR_QUOTE'
};

const emptyFormValues = {
  sibling: 'no',
  numberOfPeopleRequesting: '1 person',
  title: '',
  firstName: '',
  middleName: '',
  lastName: '',
  gender: '',
  dateOfBirth: {
    day: '',
    month: '',
    year: ''
  },
  email: '',
  mobileNumber: '',
  licenceNumber: '',
  licenceState: '',
  hasDriversLicence: HasLicenceOptions.notAnswered, // lm-3985migrated au and broker (but not gem) from using this ternary 'hasDriversLicence' field to hide / show new 'State of Issue' DL field.
  residentialAddress: {
    autoAddress: {},
    cantFindAutoAddress: false,
    unitNumber: '',
    streetNumber: '',
    streetName: '',
    streetType: '',
    suburb: '',
    city: '',
    state: '',
    postcode: '',
    propertyName: ''
  },
  previousAddress: {
    autoAddress: {},
    cantFindAutoAddress: false,
    unitNumber: '',
    streetNumber: '',
    streetName: '',
    streetType: '',
    suburb: '',
    city: '',
    state: '',
    postcode: '',
    propertyName: ''
  },
  timeAtCurrentAddress: {
    years: '',
    months: ''
  },
  residentialStatus: '',
  incomeFrom: '',
  occupation: '',
  timeAtCurrentEmployment: {
    years: '',
    months: ''
  },
  consentToRequestCreditReport: false,
  consentToCreditScoreNotAffected: false,
  consentPrivacy: false,
  startDate: new Date()
};

const initialSoftQuoteState = {
  page: PAGES.ABOUT_YOU,
  softQuoteWidgetInfo: '',
  formValues: emptyFormValues
};

function personalLoanSoftQuoteReducer(state, action) {
  let softQuoteWidgetInfo = action.softQuoteWidgetInfo;
  switch (state.page) {
    case PAGES.ABOUT_YOU: {
      switch (action.type) {
        case 'EMPLOYMENT': {
          return {
            ...state,
            page: PAGES.EMPLOYMENT,
            ...softQuoteWidgetInfo,
            formValues: action.formValues
          };
        }
        default: {
          return state;
        }
      }
    }
    case PAGES.EMPLOYMENT: {
      switch (action.type) {
        case 'YOUR_QUOTE': {
          return {
            ...state,
            page: PAGES.YOUR_QUOTE,
            ...softQuoteWidgetInfo,
            formValues: action.formValues
          };
        }
        case 'ABOUT_YOU': {
          return {
            ...state,
            page: PAGES.ABOUT_YOU,
            ...softQuoteWidgetInfo,
            formValues: action.formValues
          };
        }
        default: {
          return state;
        }
      }
    }
    default: {
      return state;
    }
  }
}

export {
  PAGES,
  initialSoftQuoteState,
  personalLoanSoftQuoteReducer,
  emptyFormValues
};
