import React from 'react';
import Layout from '@/components/layout';
import Metadata from '@/components/Metadata/Metadata';
import { emptyFormValues } from '@/components/PersonalLoanSoftQuote/pages-au/PersonalLoanSoftQuoteReducer';
import { YourQuote } from '@/components/PersonalLoanSoftQuote/pages-au/PersonalLoanSoftQuoteYourQuote';
import { SoftQuoteLayout } from '@/components/PersonalLoanSoftQuote/SoftQuoteLayout';
import {DatadogAppender} from '@/components/PersonalLoanSoftQuote/DatadogAppender';

export default props => {
  return (
    <Layout location={props.location} noHeader noFooter>
      <Metadata {...PageData.metaData} />
      <DatadogAppender/>
      <SoftQuoteLayout>
        <YourQuote
          state={{ formValues: emptyFormValues }}
          dispatch={() => {}}
          queryString={props.location.search}
        />
      </SoftQuoteLayout>
    </Layout>
  );
};

const PageData = {
  metaData: {
    title: 'Your quote',
    description: '',
    keywords: '',
    canonical: ''
  }
};
