import React, { useContext } from 'react';
import { AnalyticsContext, trackEvent } from 'latitude-analytics';
import { Grid, Text, Container, Link } from 'basis';
import SvgInline from '@latitude/svg-inline';
import _ from 'lodash';
import { getSalesforceBrokerUrl } from '@/utils/getUrlFromEnvUtil';

const ReferredMessage = props => {
  const broker = props.broker;
  const isGem = props.isGem;
  const pageTitle = broker
    ? `Thanks for providing ${_.capitalize(props.name)}'s details`
    : `Thanks for providing your details, ${_.capitalize(props.name)}`;
  const contactInfo = isGem
    ? 'tel:0800422898'
    : broker
    ? 'tel:1800035902'
    : 'tel:1300973422';

  let [analytics] = useContext(AnalyticsContext);
  if (isGem) analytics.siteData.page.siteCountry = 'nz';

  return (
    <Container margin="0 4 4 4">
      <Grid preset="page" rowsGap={7}>
        <Grid.Item colSpan="all" colSpan-lg="2-9">
          <Text as="h3" textStyle="heading3" align="center">
            {pageTitle}
          </Text>
        </Grid.Item>

        <Grid.Item colSpan="all" colSpan-md="2-5" colSpan-lg="3-8">
          {!broker && (
            <Text align="center" color="grey.t75" margin="0 0 4 0">
              We’re reviewing your quote details and we’ll be in touch within 1 business day to provide an update by email.
            </Text>
          )}
          {broker && (
            <>
              <Text align="center" color="grey.t75" margin="0 0 4 0">
                We&apos;re unable to verify your client&apos;s details at this stage
                but we&apos;ll update the status of their quote within the next 2 business hours.
              </Text>
              <Text align="center" color="grey.t75" margin="0 0 4 0">
                You can track the status of this quote by logging into the{' '}
                <Link href={getSalesforceBrokerUrl()} newTab={false}>Broker&nbsp;Dashboard</Link>.
              </Text>
            </>
          )}
          <Text align="center" color="grey.t75">
            {broker ? `However, if you need a response urgently please call us on ` : `If you need anything in the meantime, please call us on `}
            <Link
              href={contactInfo}
              onClick={() => {
                trackEvent(analytics, {
                  category: 'quote',
                  action: 'phone-link',
                  location: 'Referred'
                });
              }}
              newTab={false}
            >
              {isGem
                ? '0800 422 898'
                : broker
                ? '1800 035 902'
                : '1300 973 422'}
            </Link>{' '}
            <br />
            {isGem ? (
              <>Mon to Fri: 8:30am - 6:30pm (NZST)</>
            ) : broker ? (
              <>Mon to Fri: 9am - 5pm (AEST)</>
            ) : (
              <>Mon to Fri: 8am - 6pm (AEST)</>
            )}
            <br /> Weekends: Closed
          </Text>
        </Grid.Item>

        <Grid.Item colSpan="all" colSpan-md="2-5" colSpan-lg="3-8">
          <SvgInline
            name="snapfingers"
            css="display:block; width:80px; margin: 0 auto;"
          />
        </Grid.Item>
      </Grid>
    </Container>
  );
};

export default ReferredMessage;
