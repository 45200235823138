import React from 'react';
import { Container, Sticky } from 'basis';
import { SoftQuoteHeader } from '@/components/PersonalLoanSoftQuote/Header';
import { SimplifiedFooter } from '@/components/PersonalLoanSoftQuote/SimplifiedFooter';

function NonFormLayout({ children, product, containerProps }) {
  return (
    <Sticky>
      <Sticky.Item>
        <SoftQuoteHeader product={product} />
      </Sticky.Item>
      <Container
        bg="grey.t03"
        padding="0"
        padding-lg="1"
        margin="0 0 0 0"
        {...containerProps}
      >
        <Container
          padding="0"
          margin="0"
          margin-md="0"
          margin-lg="12 auto 7"
          hasBreakpointWidth={false}
          hasBreakpointWidth-lg
        >
          <Container
            bg="white"
            padding="10 0"
            padding-xs="10 4"
            padding-md="10"
            padding-lg="10 8"
            hasBorder
            margin="0"
          >
            {children}
          </Container>
        </Container>
      </Container>
      <SimplifiedFooter product={product} />
    </Sticky>
  );
}

export { NonFormLayout };
